import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { RiArrowRightSLine } from "react-icons/ri"

// OPTION 1
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1em 1fr 1em;
  grid-template-rows: auto 2em auto;
`

const TextWrapper = styled.div`
  grid-row: 1 / span 2;
  grid-column: 1 / -1;

  padding: 2em;
  border-radius: var(--br);
  color: var(--txt-light);
  height: 100%;
  min-height: 450px;
  background: ${props => `url(${props.img})`}, var(--clr-overlay);
  background-blend-mode: overlay;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
`

const Description = styled.p``

const Button = styled.p`
  grid-row: 2 / span 2;
  grid-column: 2 / 3;
  padding: 1em;
  text-align: center;
  background: var(--clr-light-secondary);
  border: 1px solid var(--clr-dark);
  color: var(--txt-dark);
  font-size: var(--fs-sm);
  font-weight: var(--fw-button);
  letter-spacing: 0.1em;
  text-transform: uppercase;

  display: flex;
  justify-content: center;
  align-items: center;
`

export default function ServiceCard(props) {
  return (
    <StyledLink to={props.to}>
      <GridWrapper>
        <TextWrapper className="spacing" img={props.img}>
          <h3 className="body body--large">{props.title}</h3>
          <Description>{props.description}</Description>
        </TextWrapper>
        <Button>
          <span>see more</span> <RiArrowRightSLine />
        </Button>
      </GridWrapper>
    </StyledLink>
  )
}
// END OPTION 1

// OPTION 2
// const GridWrapper = styled.div`
//   display: grid;
//   grid-template-columns: 1em 1fr 1em;
//   grid-template-rows: auto 2em auto;
//   position: relative;
//   img {
//     grid-row: 1 / span 2;
//     grid-column: 1 / -1;

//     height: 400px;
//     width: 100%;
//     object-fit: cover;
//   }
// `

// const Text = styled.div`
//   grid-row: 2 / span 2;
//   grid-column: 2 / 3;
//   padding: 1em;
//   text-align: center;
//   background: var(--clr-light-secondary);
//   color: var(--txt-dark);
//   border: 1px solid var(--clr-dark);

//   p {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// `

// const StyledLink = styled(props => <Link {...props} />)`
//   text-decoration: none;
//   color: var(--clr-accent);
// `

// export default function ServiceCard(props) {
//   return (
//     <StyledLink to={props.to}>
//       <GridWrapper>
//         <img src={props.img} alt="royco electrical services" />
//         <Text>
//           <p className="body--large">
//             <span>{props.title}</span> <RiArrowRightSLine />
//           </p>
//         </Text>
//       </GridWrapper>
//     </StyledLink>
//   )
// }
// END OPTION 2
