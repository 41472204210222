import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServicesFull from "../components/services/servicesFull"
import BannerActions from "../components/banners/bannerActions"

import Banner from "../images/banner.png"

export default function Services() {
  return (
    <Layout>
      <SEO title="Residential, Commercial, Industrial Electrician Toronto and GTA" />
      <ServicesFull />
      <div className="section-splitter-bottom"></div>
    </Layout>
  )
}
