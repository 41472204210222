import React from "react"
import styled from "styled-components"

import { Section, Container, GridThree } from "../layoutComponents"
import ServiceCard from "./serviceCard"

import CommercialServices from "../../images/services/commercial-services.png"
import ResidentialServices from "../../images/services/residential-services.png"
import IndustrialServices from "../../images/services/industrial-services.png"

const ServiceWrapper = styled.section`
  display: grid;
  grid-template-rows: auto 200px auto;
`
const Bg = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / span 2;
  background-color: var(--clr-dark);
  width: 100%;
  height: 100%;
  z-index: -1000;
`

const Top = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  color: var(--txt-light);
  padding: 4em 0;

  h1,
  h2,
  h3,
  p {
    max-width: 70ch;
    width: 100%;
  }
`

const Bottom = styled.div`
  grid-row: 2 / -1;
  grid-column: 1 / -1;
  z-index: 1;
`

export default function ServicesFull() {
  return (
    <ServiceWrapper>
      <Bg />
      <Top>
        <Container className="spacing">
          <h2 className="title">
            we provide residential, commercial, and industrial electrical
            services throughout the GTA & muskoka, ON.
          </h2>
        </Container>
      </Top>
      <Bottom>
        <Container>
          <GridThree>
            <ServiceCard
              to="/services/residential"
              img={ResidentialServices}
              title="residential"
              description="Complete service upgrades, new construction and renovations, generator installation, and more."
            />
            <ServiceCard
              to="/services/commercial"
              img={CommercialServices}
              title="commercial"
              description="Service upgrades & maintenance, equipment connections, office renovations, and more."
            />
            <ServiceCard
              to="/services/industrial"
              img={IndustrialServices}
              title="industrial"
              description="Power distribution, machinery moving and installations, shut down maintenance, service calls, and more."
            />
          </GridThree>
        </Container>
      </Bottom>
    </ServiceWrapper>
  )
}
